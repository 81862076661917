import {
	donkerblauw,
	hemelblauw,
	neutral,
} from '@dop/ui-primitives/color/colors';

import { ColorTheme } from '../ColorTheme.types';

export const colorThemeBase: Omit<
	ColorTheme,
	'name' | 'negative' | 'positive' | 'text'
> & {
	negative: Omit<ColorTheme['negative'], 'primary'>;
	positive: Omit<ColorTheme['positive'], 'primary'>;
	text: Omit<ColorTheme['text'], 'primary'>;
} = {
	negative: {
		secondary: {
			normal: hemelblauw[100],
			hover: hemelblauw[130],
			active: hemelblauw[160],
		},
		normal: {
			normal: neutral.white,
			hover: neutral[30],
			active: neutral[60],
		},
	},
	positive: {
		secondary: {
			normal: hemelblauw.base,
			hover: hemelblauw[650],
			active: hemelblauw[680],
		},
		normal: {
			normal: donkerblauw.base,
			hover: donkerblauw[730],
			active: donkerblauw[760],
		},
		muted: {
			normal: neutral[400],
			hover: neutral[400],
			active: neutral[400],
		},
	},
	text: {
		secondary: {
			normal: donkerblauw.base,
			hover: donkerblauw[760],
			active: donkerblauw[820],
		},
		normal: {
			normal: neutral[800],
			hover: neutral[860],
			active: neutral[920],
		},
		muted: {
			normal: neutral[600],
			hover: neutral[600],
			active: neutral[600],
		},
		primaryContrast: {
			normal: neutral.white,
			hover: neutral.white,
			active: neutral.white,
		},
		secondaryContrast: {
			normal: neutral.white,
			hover: neutral.white,
			active: neutral.white,
		},
		normalContrast: {
			normal: neutral.white,
			hover: neutral.white,
			active: neutral.white,
		},
		mutedContrast: {
			normal: neutral.black,
			hover: neutral.black,
			active: neutral.black,
		},
	},
};
