import { donkerblauw, hemelblauw } from '@dop/ui-primitives/color/colors';

import { OPColorTheme } from '../ColorTheme.types';
import { colorThemeBase } from '../globalDefinitions/colorThemeBase';

export const rootColorThemeOP: OPColorTheme = {
	name: 'Ondernemersplein.kvk.nl',
	project: 'OP',
	negative: {
		...colorThemeBase.negative,
		// negative primary is identical to secondary
		primary: colorThemeBase.negative.secondary,
	},
	positive: {
		...colorThemeBase.positive,
		primary: {
			normal: donkerblauw[850],
			hover: donkerblauw[880],
			active: donkerblauw[910],
		},
	},
	text: {
		...colorThemeBase.text,
		primary: {
			normal: hemelblauw[850],
			hover: hemelblauw[910],
			active: hemelblauw[970],
		},
	},
};
