// See: https://ondernemersportaal.atlassian.net/browse/DOP-7482
export const BLOOMREACH_IMAGE_VARIANTS = {
	LARGE: 'large', // width: 1920, height: ?, vertical-origin: top
	MEDIUM: 'medium', // width: 850, height: ?, vertical-origin: top
	CONTENT: 'content', // width: 600, height: ?, vertical-origin: top
	HEADER: 'header', // width: 500, height: 500, origin: center center
	BIO: 'bio', // biography-image, width: 100, height: 100, vertical-origin: top
	PROMO: 'promo', // width: 448, height: 252, vertical-origin: top
	MENU: 'menu', // width: 120, height: 68, vertical-origin: top
	// Bloomreach defaults:
	THUMBNAIL: 'thumbnail', // width: 60, height: 60, origin: center center
};
